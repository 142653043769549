import BaseReducer from "../BaseReducer";
import DotsAction from "./dotsAction";

export default class DotsReducer extends BaseReducer {
  initialState = {
    nodesLoading: false,
    nodes: [],
    browserMode: 'regular', //dots
    selectedTargets: [],
    modalActive: null,
    modalData: {},
  };

  /* SET ANY PROPERTY TO Dots store*/
  [DotsAction.UPDATE_DOTS_STATE](state, action) {
    return {
      ...state,
      ...action.payload,
    }
  }

}
